//
// Toolbar
//

// General
.toolbar {
  display: flex;
  align-items: center;
  background-color: var(--bs-gray-100);
  box-shadow: 0 0.5rem 1rem 0.1rem rgba(0, 0, 0, 0.05);
  // Custom button
  .btn.btn-custom {
    background-color: rgba($white, 0.1);
  }
}
