.modal-backdrop {
  z-index: 1055;
}

.comment span {
	background-color: unset !important;
}

.cursor-pointer {
  cursor: pointer;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-text__body {
  width: 75%;
}

.skeleton-footer {
  width: 30%;
}

.w-100vh {
  width: 100vh;
}
.h-100vh {
  height: 100vh;
}

.menu-state-bg .menu-item .menu-link.active .menu-arrow:after {
  background-color: var(--bs-menu-link-color-active) !important;
}

body {
  background-color: var(--bs-gray-200);
}

.react-datepicker__time-list-item--disabled {
  display: none;
}

.tox-statusbar__branding {
  display: none;
}

.react-datepicker {


  .react-datepicker__month-container {
    font-family: "Inter";
    .react-datepicker__header {
      background-color: white;
      border: 0;
      font-size: 14px;
      padding-top: 15px;
    }

    .react-datepicker__month-text {
      font-size: 14px;
      width: 100px;
      padding: 10px;
    }

    .react-datepicker__current-month {
      background-color: white;
      border: 0;
      font-size: 14px;
    }

    .react-datepicker__day {
      font-size: 14px;
      width: 40px;
      height: 40px;
      padding: 10px;
    }

    .react-datepicker__day--today {
      border-radius: 100%;
      background-color: #82d1ff;
      color: black;
    }
    .react-datepicker__day:hover {
      border-radius: 100%;
      background-color: #cbecff;
      color: black;
    }
    .react-datepicker__day--keyboard-selected {
      border-radius: 100%;
      background-color: #009ef7;
      color: white;
    }
    .react-datepicker__day--selected {
      border-radius: 100%;
      background-color: #009ef7;
      color: white;
    }
    .react-datepicker__day-name {
      font-size: 14px;
      width: 40px;
      height: 40px;
      padding: 10px;
      margin-top: 10px;
      font-weight: bold;
    }
  }

  .react-datepicker__time-list-item {
    font-size: 14px;
  }

  .react-datepicker__time-list-item:hover {
    background-color: #cbecff  !important;
  }

  .react-datepicker__time-list-item--selected {
    background-color: #009ef7 !important;
  }

  .react-datepicker-time__header {
    font-size: 14px;
  }
  .react-datepicker__navigation-icon {
    top: 11px;
  }
}

.react-datepicker-wrapper {
  .react-datepicker__close-icon::after {
    background-color: #a1a5b7;
  }
}

.modal-body-scroll {
  height: calc(100vh - 250px);
  overflow: scroll;
}

.minw-100px {
  min-width: 100px;
}