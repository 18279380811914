.rdt_Table {
    
    .rdt_TableHead {
        font-size: inherit;

        .rdt_TableHeadRow {
            --bs-bg-rgb-color: var(--bs-gray-300);
            background-color: var(--bs-gray-300) !important;
            border: 0;
            border-radius: 5px;
        }
    }
}
